import Image from 'next/image';
import Link from 'next/link';
import { Col, Row, Tab } from 'react-bootstrap';
import { useCaseContentProps, useCaseProps } from '@/utils/types';

interface UseCaseProps {
  data: useCaseProps;
  tab: string;
}

const UseCase = ({ data, tab }: UseCaseProps) => {
  return (
    <Tab.Pane eventKey={tab}>
      <Row>
        {data.content.map((item: useCaseContentProps, index: number) => (
          <Col md={6} key={index.toString()}>
            <div className='box'>
              <h5>{item.title}</h5>
              <p>{item.descr}</p>
              <Link prefetch={false}
                href='#'
                onClick={(e) => {
                  console.log("item",item.title)
                  e.preventDefault();
                  if (e?.ctrlKey) {
                    data?.heading === 'Case Studies'
                      ? window.open(item.link, '_blank')
                      : window.open(`industries/${item?.title.replace(/\s+/g, '-')}`, '_blank');
                  }
                  else {
                    data?.heading === 'Case Studies'
                      ? window.location.assign(item.link)
                      : window.location.assign(`industries/${item?.title.replace(/\s+/g, '-')}`);
                  }
                }}
              >
                {data?.heading === 'Case Studies'
                  ? 'View Case Study'
                  : 'Explore'}{' '}
                <Image
                  src='/assets/icons/arrow_new_home.svg'
                  width={13}
                  height={8}
                  priority={false}
                  alt='Education'
                  style={{ objectFit: 'contain' }}
                  className='img-fluid ms-1'
                />
              </Link>
            </div>
          </Col>
        ))}
      </Row>
    </Tab.Pane>
  );
};

export default UseCase;
